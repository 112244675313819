import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';


export default function RadioInput({ value, label, name }) {

  return (
    <>
      <FormControlLabel value={value} control={<Radio />} label={label}/>
    </>
  );
}
