export const charismaQuizResults = {
  not_charismatic: {
    title: 'You have a lot to learn about charisma.',
    paragraphs: [
      {
        content: "Don't worry, charisma is a skill and it can be learned!",
      },
      {
        title: 'Start with your foundation:',
        content:
          'The first thing to know about charisma is that anxiety kills it. Learning to avoid worry, getting in touch with your thoughts and becoming able to control negative emotions will take you most of the way to a charismatic state!',
        anchor: {
          content: 'In this post we cover some tips that can help you!',
          path: '/blog/stop-being-awkward',
        },
      },
      {
        title: 'Communication Techniques:',
        content:
          'Once you achieve a good mental state to interact with people, learn some communication techniques, these can be non-verbal (like body language) or verbal (some conversation kung-fu will be coming to the blog)',
        anchor: {
          content: 'Here are some examples of techniques you can learn!',
          path: '/blog/7-tricks-on-how-to-keep-conversations-going',
        },
      },
    ],
    range: [-31, -5], // bottom, top
  },
  slighty_charismatic: {
    title: 'Not very charismatic! You need to learn a few things.',
    paragraphs: [
      {
        content: "Don't worry, charisma is a skill and it can be practiced!",
      },
      {
        title: 'Start with your foundation:',
        content:
          'The first thing to know about charisma is that anxiety kills it. Learning to avoid worry, getting in touch with your thoughts and becoming able to control negative emotions will take you most of the way to a charismatic state!',
        anchor: {
          content: 'In this post we cover some tips that can help you!',
          path: '/blog/stop-being-awkward',
        },
      },
      {
        title: 'Communication Techniques:',
        content:
          'Once you achieve a good mental state to interact with people, learn some communication techniques, these can be non-verbal (like body language) or verbal (some conversation kung-fu will be coming to the blog)',
        anchor: {
          content: 'Here are some examples of techniques you can learn!',
          path: '/blog/7-tricks-on-how-to-keep-conversations-going',
        },
      },
    ],
    range: [-4, 12],
  },
  charismatic: {
    title: 'You are charismatic! But you can do better.',
    paragraphs: [
      {
        content:
          'You did good, but here are some insights that can help you out!',
      },
      {
        title: 'Start with your foundation:',
        content:
          'The first thing to know about charisma is that anxiety kills it. Learning to avoid worry, getting in touch with your thoughts and becoming able to control negative emotions will take you most of the way to a charismatic state!',
        anchor: {
          content: 'In this post we cover some tips that can help you!',
          path: '/blog/stop-being-awkward',
        },
      },
      {
        title: 'Communication Techniques:',
        content:
          'Once you achieve a good mental state to interact with people, learn some communication techniques, these can be non-verbal (like body language) or verbal (some conversation kung-fu will be coming to the blog)',
        anchor: {
          content: 'Here are some examples of techniques you can learn!',
          path: '/blog/7-tricks-on-how-to-keep-conversations-going',
        },
      },
    ],
    range: [13, 24],
  },
  very_charismatic: {
    title: 'Very charismatic! But there is room for improvement.',
    paragraphs: [
      {
        content: 'Nice job! But if you want to improve, remember:',
      },
      {
        title: 'Start with your foundation:',
        content:
          'The first thing to know about charisma is that anxiety kills it. Learning to avoid worry, getting in touch with your thoughts and becoming able to control negative emotions will take you most of the way to a charismatic state!',
        anchor: {
          content: 'In this post we cover some tips that can help you!',
          path: '/blog/stop-being-awkward',
        },
      },
      {
        title: 'Communication Techniques:',
        content:
          'Once you achieve a good mental state to interact with people, learn some communication techniques, these can be non-verbal (like body language) or verbal (some conversation kung-fu will be coming to the blog)',
        anchor: {
          content: 'Here are some examples of techniques you can learn!',
          path: '/blog/7-tricks-on-how-to-keep-conversations-going',
        },
      },
    ],
    range: [25, 34],
  },
};
