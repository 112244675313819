export const charismaQuizQuestions = [
    {
        question:"Most days you feel...",
        type:'selection',
        values:["Anxious or stressed.", "Confident or calm."],
        category: "Power",
        points:[-3, 3]
    },
    {
        question:"My mind often wanders when someone is speaking to me.",
        type:'range',
        values:["Never", "Rarely", "Sometimes", "Often", "Very Often"],
        category: "Presence",
        points:[2, 1, 0, -1 ,-2]
    },
    {
        question:"I find most people to be interesting.",
        type:'selection',
        values:['False','True'],
        category: "Empathy",
        points:[-2, 2]
    },
    {
        question:"Do you delay your reactions to what people say?",
        type:'selection',
        values:['No, I tend to react imediately.','Yes, I focus on what they are saying.'],
        category: "Presence",
        points:[-3, 3]
    },
    {
        question:"When I meet somebody new, I ask them more questions than they ask me...",
        type:'range',
        values:["Never", "Rarely", "Sometimes", "Often", "Very Often"],
        category: "Power",
        points:[-2, -1, 0, 1 ,2]
    },
    {
        question:"When someone offends you, do you...",
        type:'selection',
        values:["Change the subject or stay silent.", "Try to change their minds about you.", "Call them out.", "Find a way to offend them."],
        category: "Power",
        points:[0, -1, 2, -3]
    },
    {
        question:"People tell me that I’m funny or have a good sense of humor.",
        type:'range',
        values:["Never", "Rarely", "Sometimes", "Often", "Very Often"],
        category: "Power",
        points:[-1, -1, 0, 1 ,2]
    },
    {
        question:"When you speak how often do people interrupt you?",
        type:'range',
        values:["Never", "Rarely", "Sometimes", "Often", "Very Often"],
        category: "Presence",
        points:[2, 2, 0, -1 ,-2]
    },
    {
        question:"In conversations, how often do you focus on understanding what others say?",
        type:'range',
        values:["Never", "Rarely", "Sometimes", "Often", "Very Often"],
        category: "Empathy",
        points:[-2, -2, 1, 2 ,3]
    },
    {
        question:"You identify yourself as...",
        type:'selection',
        values:['Introvert','Extrovert', 'Neither/both, it varies'],
        category: "Power",
        points:[-1, 1, 2]
    },
    {
        question:"How often do you lose your attention?",
        type:'range',
        values:["Never", "Rarely", "Sometimes", "Often", "Very Often"],
        category: "Presence",
        points:[2, 1, -1, -1 ,-2]
    },
    {
        question:"How empathetic are you?",
        type:'range',
        values:["Not at all", "Sort of", "Somewhat", "Very"],
        category: "Empathy",
        points:[-2, -1, 1, 2 ]
    },
    {
        question:"When people tell you about their experiences, do you...",
        type:'selection',
        values:["Change the subject or stay silent.", "Imagine how they were affected.", "Focus on how you should react to their story."],
        category: "Presence",
        points:[-1, 2, -2 ]
    },
    {
        question:"How often do you pay attention to your current state of mind?",
        type:'range',
        values:["Never", "Rarely", "Sometimes", "Often", "Very Often"],
        category: "Presence",
        points:[-2, -1, 0, 1 ,2]
    },
    {
        question:"Are you able to calm yourself after being triggered by someone or something?",
        type:'range',
        values:["Never", "Rarely", "Sometimes", "Often", "Very Often"],
        category: "Presence",
        points:[-2, -1, 1, 2 ,3]
    },
]