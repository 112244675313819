import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SEO from '../../components/seo';
import Layout from '../../components/layout';

import { InputMap } from './components/InputMap';
import { charismaQuizQuestions } from './questions';
import { charismaQuizResults } from './results';

const QuizLayout = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  margin: 0 100px;
  padding: 50px;
  border: 3px solid rgba(255, 205, 56, 1);
  @media (max-width: 900px) {
    margin: 10px 15px;
  }
  @media (max-width: 500px) {
    margin: 5px;
    padding: 10px;
    border: none;
  }
`;

const SpacerWrap = styled.div`
  margin: 30px;
  text-align: center;
  width: 100%;
  @media (max-width: 900px) {
    margin: 30px 0;
  }
  @media (max-width: 500px) {
    margin: 5px 0;
  }
`;

const InputWrap = styled(SpacerWrap)`
  max-width: 600px;
  width: 90%;
  span {
    font-weight: 700;
    font-size: 1.15em;
    @media (max-width: 900px) {
      font-size: 0.98em;
    }
    @media (max-width: 500px) {
      font-weight: 400;
      font-size: 0.94em;
    }
  }
  @media (max-width: 900px) {
    max-width: 100%;
    width: 100%;
    margin: 40px 20px;
  }
`;

const Title = styled.h1`
  letter-spacing: 3px;
  text-align: center;
  @media (max-width: 900px) {
    font-size: 1.5em;
  }
`;

const Question = styled.h2`
  font-weight: 300;
  letter-spacing: 2px;
  background-color: rgba(255, 205, 56, 0.3);
  display: inline-block;
  padding: 15px;
  border-radius: 25px;
  @media (max-width: 900px) {
    font-size: 1.2em;
  }
`;

const NextBtn = styled.button`
  background-color: ${props =>
    props.disabled ? 'rgba(255, 205, 56, 0.7)' : 'rgba(255, 205, 56, 1)'};
  border: none;
  border-radius: 15px;
  padding: 25px 45px;
  color: black;
  font-size: 1.4em;
  font-weight: 700;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${props =>
      props.disabled ? 'rgba(255, 205, 56, 0.4)' : 'rgba(255, 205, 56, 1)'};
  }
  @media (max-width: 900px) {
    font-size: 1.1em;
  }
`;

const ParagraphWrap = styled.div`
  max-width: 700px;
  margin: 30px auto;
`;

const Paragraph = styled.p`
  font-size: 1.15em;
  line-height: 1.65em;
  @media (max-width: 900px) {
    font-size: 1.1em;
  }
`;

const ResultTitle = styled(Question)`
  background-color: rgba(255, 205, 56, 0.8);
`;

const FinalResultRender = ({ finalResult }) => {
  const { title, paragraphs } = finalResult;
  console.log(paragraphs);
  return (
    <>
      <Title>Result:</Title>
      <ResultTitle>{title}</ResultTitle>

      {paragraphs.map((p, i) => (
        <ParagraphWrap key={'p' + i}>
          {p.title && <h3>{p.title}</h3>}
          {p.content && <Paragraph>{p.content}</Paragraph>}
          {p.anchor && (
            <a href={'https://suaveway.com' + p.anchor.path} target="_blank">
              {p.anchor.content}
            </a>
          )}
        </ParagraphWrap>
      ))}
    </>
  );
};

const Spacer = styled.div`
  padding: 50px;
  @media (max-width: 900px) {
    padding: 30px;
  }
`;

export default function QuizApp({ questions = charismaQuizQuestions }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentResult, setCurrentResult] = useState([]);
  const [finalResult, setFinalResult] = useState(null);
  const [isNextDisabled, setNextDisabled] = useState(true);

  const { question, type, values, points } = questions[currentQuestion];

  useEffect(() => {
    setNextDisabled(true);
  }, [currentQuestion]);

  const handleNext = () => {
    const nextIndex = currentQuestion + 1;

    if (questions.length > nextIndex) {
      setCurrentQuestion(nextIndex);
    }

    if (questions.length - 1 == currentQuestion) {
      const finalRes = calculatePointsResult();
      setFinalResult(finalRes);
    }
  };

  const calculatePointsResult = () => {
    let totalPoints = 0;

    currentResult.forEach(res => {
      totalPoints += res.points;
    });

    // match totalPoints to results dictionary
    const resultKeys = Object.keys(charismaQuizResults);

    for (let i = 0; i < resultKeys.length; i++) {
      const currentResultKey = resultKeys[i];
      const currentResult = charismaQuizResults[currentResultKey];
      const range = currentResult.range;

      if (totalPoints >= range[0] && totalPoints <= range[1]) {
        const finalResult = { ...currentResult, totalPoints };
        return finalResult;
      }
    }
  };

  const handleAnswer = answerIndex => {
    const questionIndex = currentQuestion;
    const currentResultClone = [...currentResult];
    const resultObj = {
      questionIndex: questionIndex,
      question: questions[currentQuestion],
      answer: values[answerIndex],
      answerIndex: answerIndex,
      points: points[answerIndex],
    };

    currentResultClone[questionIndex] = resultObj;
    setNextDisabled(false);
    setCurrentResult(currentResultClone);
  };

  return (
    <Layout disableHeader>
      <SEO title="Charisma Quiz" />
      <Spacer />
      <QuizLayout>
        <Title>Quiz: Are You Charismatic?</Title>
        <SpacerWrap>
          {!finalResult && <Question>{question}</Question>}
        </SpacerWrap>

        {finalResult ? (
          <SpacerWrap>
            <FinalResultRender finalResult={finalResult} />
            {/* FINAL RESULT: {JSON.stringify(finalResult)} */}
          </SpacerWrap>
        ) : (
          <InputWrap>
            <InputMap
              action={handleAnswer}
              type={type}
              values={values}
              currentQuestion={currentQuestion}
            />
          </InputWrap>
        )}

        <SpacerWrap>
          {!finalResult ? (
            isNextDisabled ? (
              <NextBtn disabled>Select An Answer To Continue</NextBtn>
            ) : (
              <NextBtn onClick={handleNext}>Next</NextBtn>
            )
          ) : (
            <div />
          )}
        </SpacerWrap>
      </QuizLayout>
    </Layout>
  );
}
