import React, {useState, useEffect} from 'react'
import RadioGroup from '@material-ui/core/RadioGroup';
import {RadioInput} from '../RadioInput'
import {Slider} from '../Slider'

const formSliderMarks = (values) => {
    const output = []
    
    values.forEach((val, i) => {
        const mark = {}
        mark.value = i 
        mark.label = val

        output.push(mark)
    })

    return output
}

export default function InputMap({type, values, action, currentQuestion}) {
    const [sliderValue, setSliderValue] = useState(0)

    useEffect(()=>{
        setSliderValue(0)
    }, [currentQuestion])

    if(type == 'range'){

        const marks = formSliderMarks(values)

        const sliderActionAdapter = (event, answerIndex) => {
            setSliderValue(answerIndex)
            action(answerIndex)
        }

        return (
            <> 
                <Slider
                    value = {sliderValue}
                    onChange={sliderActionAdapter}
                    marks={marks}
                    step={1}
                    max={(values.length - 1)}
                    key={values.toString()}
                />
            </>
        )
    }
    
    if(type == 'selection'){

        const radioActionAdapter = (e) => {
            const textVal = e.target.value
            const answerIndex = values.indexOf(textVal)
            action(answerIndex)
        }

        return (
            <RadioGroup onChange={radioActionAdapter}>
                {values.map(val => {
                   return <RadioInput value={val} label={val} name={type}/>
                })}
            </RadioGroup>
        )
    }
    
}
